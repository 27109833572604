import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'es6-promise/auto'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

const ONE_MINUTE = 60 * 1000 * 3
const MAX_FONT_SIZE = 150
const MIN_FONT_SIZE = 100

const state = {
    loaded: false,
    expiryDate: new Date,
    version: 0,
    properties:[],
    currentFilterOptions: [
        { label: "Any", value: "any" }, //listing
        { label: "Available", value: "available" }, //status
        { label: "Any", value: [0, 5000000] }, // price
        { label: "Any", value: [0, 10] }, // beds
        { label: "Any", value: [0, 10] }, // baths
        { label: "Any", value: "any" }, // type
        { label: "Any", value: "any" }, // pets
        { label: "Any", value: "any" }, // laundry
        'Any' //City
    ],
    searchText: '',
    accessibility:{
        showAccessibilityBar: true,
        fontSize: 100,
        accessibilityMode: false,
    },
    currentPropertyIndex: 0,
    showMap: true,
    account: null,
}

let rentalPromise = new Promise(async function(resolve, reject){
    //let r = Math.random().toString(36).substring(7);
    let rental = [];
    let result = await axios.get(
        `https://strapi.cerda-zein.com/api/rentals?fields[0]=Slug&fields[1]=Price&fields[2]=Address&fields[3]=City&fields[4]=Status&fields[5]=lat&fields[6]=lng&fields[7]=Bedrooms&fields[8]=Bathrooms&fields[9]=BuildingType&fields[10]=Pets&fields[11]=Laundry&fields[12]=Subtitle&fields[13]=ServicePage&fields[14]=ServicePageRedirect&populate[0]=FeaturedImage&sort=Price:asc`
    )
    rental = result.data.data.map(
        r => ({ 
            ...r.attributes,
            category: 'rental' 
        })
    )
    resolve(rental);
    reject("error")
})

let listingPromise = new Promise(async function(resolve, reject){
    //let r = Math.random().toString(36).substring(7);
    let listing = [];
    let result = await axios.get(`https://strapi.cerda-zein.com/api/listings?fields[0]=Slug&fields[1]=Price&fields[2]=Address&fields[3]=City&fields[4]=Status&fields[5]=lat&fields[6]=lng&fields[7]=Bedrooms&fields[8]=Bathrooms&fields[9]=BuildingType&fields[10]=Pets&fields[11]=Laundry&fields[12]=Subtitle&fields[13]=ServicePage&fields[14]=ServicePageRedirect&populate[0]=FeaturedImage&sort=Price:asc`);
    listing = result.data.data.map(
        l => ({ 
            ...l.attributes,
            category: 'listing'
        })
    );
    resolve(listing);
    reject("error");
})

// getters
const getters = {
    checkExpiryDate: (state) => {
        return ((new Date) - state.expiryDate) > ONE_MINUTE;
    },
    getLoaded: (state) => {
        return state.loaded;
    },
    properties: (state) => {
        return state.properties
    },
    currentFilterOptions: (state) => {
        return state.currentFilterOptions;
    },
    accessibilitySetting: (state) => {
        return state.accessibility;
    },
    nextPropertyURL: (state) => {
        let category = '';
        let slug = '';
        if(state.properties.length === 0){
            return '';
        }
        if(state.currentPropertyIndex === state.properties.length -1){
            category = state.properties[0].category;
            slug = state.properties[0].Slug;
        } else {
            category = state.properties[state.currentPropertyIndex+1].category;
            slug = state.properties[state.currentPropertyIndex+1].Slug;
        }
        return `/property/${category}s/${slug}`;
    },
    prevPropertyURL: (state) => {
        let category = '';
        let slug = '';
        if(state.properties.length === 0){
            return '';
        }
        if(state.currentPropertyIndex === 0){
            category = state.properties[state.properties.length -1].category;
            slug = state.properties[state.properties.length -1].Slug;
        } else {
            category = state.properties[state.currentPropertyIndex-1].category;
            slug = state.properties[state.currentPropertyIndex-1].Slug;
        }
        return `/property/${category}s/${slug}`;
    },
    showMap: (state) => {
        return state.showMap;
    },
    accountData: (state) => {// eslint-disable-line no-unused-vars
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        return userData;
    },
    authenticated: (state) => {// eslint-disable-line no-unused-vars
        const userData = sessionStorage.getItem("userData");
        return !!userData;
    },
    authHeader: () => {
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        const jwt = userData.jwt;
        return {
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        }
    }
}

// actions
const actions = {
    propertiesLoaded(context){
        context.commit('propertiesLoaded');
    },
    updateCurrentTime(context){
        context.commit('updateCurrentTime');
    },
    updateProperties(context){
        context.commit('updateCurrentTime');
        context.commit('getProperties');
    },
    updateFilterOption ({ commit }, newOptionObject) {
        commit('setFilter', newOptionObject)
    },
    updateSearchText({commit}, newText){
        commit('setText', newText)
    },
    setPropertyIndex({commit}, index){
        commit('setPropertyIndex', index)
    },
    increaseFontSize(context){
        context.commit('increaseFontSize')
    },
    decreaseFontSize(context){
        context.commit('decreaseFontSize')
    },
    toggleAccessibilityMode(context){
        context.commit('toggleAccessibilityMode')
    },
    turnOffAccessibilityMode(context){
        context.commit('turnOffAccessibilityMode')
    },
    login(context, userData){
        context.commit('login', userData)
    },
    logout(context){
        context.commit('logout')
    },
    toggleMap(context){
        context.commit('toggleShowMap');
    },
    showMap(context){
        context.commit('showMap');
    }

}

// mutations
const mutations = {
    getProperties (state) {
        state.properties = [];
        Promise.all([rentalPromise, listingPromise]).then(function(values) {
            state.properties = [...values[0], ...values[1]];
            function sortHelper( a, b ) {
                if ((a.Status === 'comingSoon' || a.Status === 'available') && (b.Status === 'comingSoon' || b.Status === 'available')){
                    return Number(a.Price ? a.Price : 0) < Number(b.Price ? b.Price : 0) ? 1 : -1;
                }
                else if ((a.Status === 'comingSoon' || a.Status === 'available') && !(b.Status === 'comingSoon' && b.Status === 'available')){
                    return -1;
                }
                else if (!(a.Status === 'comingSoon' || a.Status === 'available') && (b.Status === 'comingSoon' || b.Status === 'available')){
                    return 1;
                }
                else if (a.Status < b.Status){
                    return -1;
                }
                else if (a.Status > b.Status){
                    return 1;
                } else {
                    return Number(a.Price ? a.Price : 0) < Number(b.Price ? b.Price : 0) ? 1 : -1;
                }
            }
            state.properties.sort(sortHelper);
            state.loaded = true;
        });
    },
    propertiesLoaded (state){
        state.loaded = true;
    },
    updateCurrentTime (state){
        state.expiryDate = new Date;
    },
    setFilter (state, newOptionObject){
        state.currentFilterOptions[Number(newOptionObject.id)] = newOptionObject.option;
    },
    setText(state, newText){
        state.searchText = newText;
    },
    increaseFontSize(state){
        if(state.accessibility.fontSize < MAX_FONT_SIZE){
            state.accessibility.fontSize += 10;
        }
    },
    decreaseFontSize(state){
        if(state.accessibility.fontSize > MIN_FONT_SIZE){
            state.accessibility.fontSize -= 10;
        }
    },
    toggleAccessibilityMode(state){
        state.accessibility.accessibilityMode = !state.accessibility.accessibilityMode;
    },
    turnOffAccessibilityMode(state){
        state.accessibility.showAccessibilityBar = false;
    },
    toggleShowMap(state){
        state.showMap = !state.showMap;
    },
    setPropertyIndex(state, index){
        state.currentPropertyIndex = index;
    },
    showMap(state){
        state.showMap = true;
    },
    logout(state){// eslint-disable-line no-unused-vars
        state.account = null;
        sessionStorage.removeItem("userData")
    },
    login(state, userData){// eslint-disable-line no-unused-vars
        state.account = userData;
        sessionStorage.setItem("userData", JSON.stringify(userData));
    }
}

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})