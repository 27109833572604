import Vue from 'vue';
import App from './App.vue';
import store from './store/index';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import * as VueGoogleMaps from 'vue2-google-maps';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueLazyload from 'vue-lazyload';
import "./filters";
import ReadMore from 'vue-read-more';
import VueWindowSize from 'vue-window-size';
import VueSimpleAlert from "vue-simple-alert";
import vmodal from 'vue-js-modal';
import PrimeVue from 'primevue/config';
import VueFormulate from "@braid/vue-formulate";
import ToastService from 'primevue/toastservice';


//icons 
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInstagram, faLinkedin, faTwitterSquare, faFacebookSquare, faYelp } from '@fortawesome/free-brands-svg-icons';
import { faPhoneAlt, faEnvelope, faSearch, faCaretDown, faCaretUp, faPlusSquare, faMinusSquare, faArrowLeft, faArrowRight, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import AOS from "aos";
import "aos/dist/aos.css";

import VueLodash from 'vue-lodash'
import lodash from 'lodash'

library.add(faInstagram, faLinkedin, faTwitterSquare, faFacebookSquare, faPhoneAlt, faEnvelope, faSearch, faCaretDown, faCaretUp, faYelp, faPlusSquare, faMinusSquare, faArrowLeft, faArrowRight, faPlusCircle, faMinusCircle)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(VueAxios, axios)
Vue.use(VueLazyload)
Vue.use(VueI18n)
Vue.use(ReadMore);
Vue.use(VueWindowSize);
Vue.use(VueSimpleAlert);
Vue.use(PrimeVue);
Vue.use(vmodal)
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(VueFormulate);
Vue.use(ToastService);


function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}

function lazyLoadAuthenicated(view){
  return() => import(`@/views/authentication/${view}.vue`)
}

Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: '/images/loading.gif',
  attempt: 1
})

Vue.use(VueGoogleMaps, {
  load: {
    key:'AIzaSyClcrOhxKRmW9SCnOYIkol5B86zQJRpQoQ',
    //key: 'AIzaSyClcrOhxKRmW9SCnOYIkol5B86zQJRpQoQ',
    libraries: 'places',
  },
})

// Ready translated locale messages
const messages = {
  en: {
    message: require('./locales/en-US.json')
  },
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
})

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: lazyLoad('Home'), name: 'Home' },
    { path: '/service', component: lazyLoad('Service'), name: 'Service'},
    { path: '/rental-submission', component: lazyLoad('RentalForm'), name: 'Rental-Form'},
    { path: '/inquire', component: lazyLoad('Inquire'), name: 'Inquire'},
    { path: '/team', component: lazyLoad('Team'), name: 'Team'},
    { path: '/client_login', component: lazyLoad('Login'), name: 'Client-Login'},
    { path: '/thankyou', component: lazyLoad('SubmissionSuccess'), name: 'Thank-you'},
    { name: 'properties', path: '/properties/:category?', component: lazyLoad('Properties')},
    { path: '/property/:category/:slug', component: lazyLoad('Property'), name: 'property'},
    { path: '/agent/:slug', component: lazyLoad('Agent'), name: 'Agent'},
    { path: '/clear-cache', component: lazyLoad('ClearCache'), name: 'Clear-cache'},
    { path: '/list-with-czre', component: lazyLoad('FlipbookView'), name: 'Flipbook'},
    { path: '/community-matters', component: lazyLoad('ServiceNew'), name: 'Service-New'},
    { path: '/terms-of-service', component: lazyLoad('TermsOfService'), name: 'Service-New'},
    { path: '/resetpassword', component: lazyLoadAuthenicated('Resetpassword'), name: 'Reset-Password'},
    { path: '/managesubscription/:id', component: lazyLoadAuthenicated('ManageSubscription'), name: 'Manage-Subscription'},
    { path: '/profile', component: lazyLoadAuthenicated('Profile'), name: 'Profile'},
    { path: '/account', component: lazyLoadAuthenicated('AlertLanding'), name: 'Account'},
    { path: '/alert/create', component: lazyLoadAuthenicated('CreateAlert'), name: 'Alert-Create'},
    { path: '/alert/:id', component: lazyLoadAuthenicated('EditAlert'), name: 'Alert-Edit'},
    { path: '/test-form', component: lazyLoad('Test')},
    { path: "*", component: lazyLoad('PageNotFound') },
  ],
  scrollBehavior (to, from, savedPosition) {
    if(savedPosition){
      return savedPosition
    }
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 10 }
      }
    }
    return { x: 0, y: 0 }
  },
})


router.beforeEach((to, from, next) => {
  const urls = ['Account', 'Profile', 'Alert-Create', 'Alert-Edit'];
  if(!sessionStorage.getItem("userData")){
    if(urls.includes(to.name)){
      next({ name: 'Home' })
    }else{
      next();
    }
  }else{
    next()
  }
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  mounted() {
    AOS.init({
      duration: 800
    })
  },
}).$mount('#app')
