<template>
    <div v-if="!inNotAllowedRoutes">
        <ContactForm v-if="!isInquirePage" />
        <div class="alert-section">
            <div class="alert-inner-container">
                <div class="info-block">
                    <img class="alert-icon" src="/images/alert.svg" alt="Email Alert Icon">
                    <h2>
                        Be the First to Know
                    </h2>
                    <p>
                        Searching for your ideal property? Stay ahead of the market with our personalized, real-time alerts. Receive Cerda-Zein Real Estate’s newest lease and sale listings, customized to your preferences, directly in your inbox. Sign up now and take the lead in your property search.
                    </p>
                    <Button class="btn orange-bg" @click="goToAlert">
                        Subscribe Now
                    </Button>
                </div>
                <div class="image-block">
                    <img src="/images/app-screenshot.webp" alt="Phone">
                </div>
            </div>
        </div>
        <FooterContents />
        <FooterCredits />
        <Authenication :display="loginModal" @toggle="toggleModal"/>
    </div>  
</template>

<script>
import FooterContents from "./Footer/FooterContents";
import FooterCredits from "./Footer/FooterCredits";
import ContactForm from "./Footer/ContactForm";
import Button from 'primevue/button';
import Authenication from "./Authenication";

export default {
    name: "Footer",
    data(){
        return {
          loginModal: false,
        }
    },
    components: {
        FooterContents,
        FooterCredits,
        ContactForm,
        Button,
        Authenication
    },
    computed:{
        inNotAllowedRoutes(){
            const urls = ['Account', 'Profile', 'Alert-Create', 'Alert-Edit'];
            return urls.includes(this.$route.name);
        },
        isInquirePage(){
            return this.$route.fullPath === '/inquire'
        }
    },
    methods:{
        goToAlert(){
            if(sessionStorage.getItem("userData")){
                this.$router.push('/account')
            } else {
                this.loginModal = true;
            }
        },
        toggleModal(value){
            this.loginModal = value;
        }
    }
}
</script>

<style lang="scss" scoped>
.alert-section{
    margin: 40px 20%;
}
.alert-inner-container{
    display: flex;
    justify-content: space-between;
}

.info-block{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    align-items:flex-start;
    margin-right: 20px;
    h2{
        margin-bottom: 0;
    }
}

.image-block{
    width: 40%;
    img{
        height: 400px;
        width: auto;
    }
}

.alert-icon{
    width: 80px;
    height: auto;
}

.btn{
    margin-top:12px;
}

@media (max-width: $tablet) {
    .alert-section{
        margin: 40px 10%;
    }

    .alert-icon{
        width: 40px;
    }

    .info-block{
        h2{
            margin-bottom: 0;
        }
    }
}
@media (max-width: $mobile) { 
    .alert-inner-container{
        flex-direction: column;
    }
    .info-block{
        width: 100%;
        align-items: center;
        h2, p{
            margin: 26px 0;
            text-align: center;
        }
        h2{
            line-height: 36px;
        }
        p{
            margin-top: 0;
        }
    }
    div{
        padding-top: 2rem;
    }
    .image-block{
        display: none;
    }
}
</style>
