<template>
  <div id="app" role="main">
    <Accessibility />
    <Header />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import Accessibility from './components/Accessibility.vue'
export default {
  name: 'app',
  components: {
    Header,
    Footer,
    Accessibility
  },
  mounted:function(){
      if(!this.$store.getters.properties.length){
          this.$store.dispatch('updateProperties');
          this.$store.dispatch('propertiesLoaded');
      } else if(this.$store.getters.checkExpiryDate){
          this.$store.dispatch('updateProperties');
      }
  },

}

import 'vueperslides/dist/vueperslides.css';
</script>

<style lang="scss">
@import '../node_modules/@braid/vue-formulate/themes/snow/snow.scss';
@import url('https://fonts.googleapis.com/css?family=Lora:400,400i|Open+Sans:400,600,700|Work+Sans:200,400&display=swap');
@import url('https://use.typekit.net/hdu0mpc.css');
@import url('https://use.typekit.net/hqc1bun.css');
html, body{
  font-family: $primaryFont;
  position: relative;
  margin:0;
  padding:0;
  height: 100%;
  min-height: 100vh;
  width:100%;
}

*{
  box-sizing: border-box;
}

button:focus{
  outline: none;
}
p{
  font-size: 0.85rem;
  line-height: 1.5rem;
}

h1, h2, h3, h4, h5, h6{
  font-family: "gravesend-sans", sans-serif;
  font-weight: 500;
  font-style: normal;
}

h2{
  font-size: 2rem;
}

.vue-simple-spinner-text{
  letter-spacing: 3px;
  font-size: 1rem !important;
  color: #000 !important;
}
.multiselect__single{
  strong{
    font-weight: 400;
  }
}
.multiselect__option--selected{
  font-weight: 400 !important;
}

.gm-style-iw{
  width: 200px;
  button{
    display: none !important;
  }
  &-c{
    padding: 0 !important;
  }
  &-d{
    max-width: unset !important;
    overflow: auto !important;
  }
}
.gm-style-iw-t{
  bottom: 42px !important;
}
.gm-style .gm-style-iw-t::after{
  top: -1px !important;
}

.multiselect__option--selected.multiselect__option--highlight{
    background: #4c4c4c !important;
}
.multiselect__option--highlight{
    background:#4c4c4c !important;
}

.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after, .vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before{
    box-shadow: none !important;
}

.vueperslides--fixed-height.vueperslides--bullets-outside{
    margin-bottom: 2em;
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after, .vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before{
    box-shadow: none;
}

.p-dialog{
    width: 600px;
}

.p-dialog-header{
    justify-content: flex-end !important;
}

.modal-title{
  color: #000;
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  text-align: center;
  margin: 10px 0;
  line-height: 36px;
}

.dialog-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-button{
  min-width: 140px;
  justify-content: center;
  text-transform: uppercase;
  padding: 12px 30px;
}

.form-button-icon{
  padding: 12px 30px;
  padding-right: 50px;
  text-transform: uppercase;
  img{
    right: 15px;
    position: absolute;
  }
}

.secondary-button{
  background: #000;
  border: #000;
  &:hover{
    background: #000 !important;
    border: #000 !important;
  }
}

.modal-content{
  text-align: center;
  max-width: 400px;
}

.modal-buttons{
  display: flex;
  gap: 40px;
  margin: 30px 0;
  .button{
    text-transform: uppercase;
  }
}
.mobile{
  display: none;
}

.p-dialog .p-dialog-header{
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.p-dialog .p-dialog-content{
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.p-tabview-nav-content{
  margin: 0 40px;
}

.p-tabview-title{
  color:#000;
  font-size: 16px;
  font-weight: 500;
}

.formulate-input[data-classification=text] input{
  font-size: 16px;
}

.formulate-input[data-classification=text] input:focus{
  border-color: #EF6A40 !important;
}

.paragraph{
  font-size: 0.85rem;
  line-height: 1.5rem;
}

.alert-page-title{
  padding: 70px 0 !important;
}

.btn{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border: 1px solid #000;
  border-radius: 8px;
  font-family: $secondaryFont;
  font-weight: 500;
  letter-spacing: 0.05em;
  font-size: 1rem;
  color:#000;
  text-decoration: unset;
  text-transform: uppercase;
  padding: 8px 20px;
  &:hover{
    cursor: pointer;
    opacity: 0.8;
  }
  &.white-border{
    color: #fff;
    border-color: #fff;
  }
  &.orange-bg{
    color: #fff;
    border-color: #EF6A40;
    background: #EF6A40;
    &:hover{
      border-color: #EF6A40;
      background: #EF6A40;
    }
  }
  &.black-bg{
    color: #fff;
    background: #000;
    border: #000;
    &:hover{
      border: #000;
      background: #000;
    }
  }
  img{
    margin:0 4px;
  }
}

@media (max-width: $mobile) {
  .desktop{
    display: none;
  }
  .mobile{
    display: block;
  }
  .p-dialog{
    width: 100%;
  }
  .authentication-title{
    line-height: 36px;
  }
}
</style>
