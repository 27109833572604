<template>
    <Dialog modal :visible.sync="modalShow" :dismissableMask="true">
        <h1 class="modal-title">
            {{ header  }}
        </h1>
        <section v-if="!reset">
            <TabView :activeIndex="tabIndex">
                <TabPanel header="Sign In">
                    <FormulateForm @submit="login" #default="{ isLoading, hasErrors }">
                        <div class="input-container">
                            <Label>Email:</Label>
                            <FormulateInput
                                type="email"
                                name="identifier"
                                placeholder="Enter Email" 
                                class="input"
                                validation="required|email"
                            />
                        </div>
                        <div class="input-container">
                            <Label>Password:</Label>
                            <FormulateInput
                                type="password"
                                name="password"
                                class="input"
                                placeholder="Enter Password"
                                validation="required|min:8,length"
                                validation-name="Password"
                            />
                        </div>
                        <InlineMessage
                            v-if="validationError"
                            class="incorrect-password-error"
                            severity="error"
                            @close="validationError = false">
                            Incorrect combination of username and password.
                        </InlineMessage>
                        <FormulateInput
                            type="submit"
                            class="button"
                            :disabled="hasErrors || isLoading"
                            :label="isLoading ? 'Submitting...' : 'Sign in'"
                        />
                    </FormulateForm>
                    <span class="link-text" @click="reset = true">
                        Forgot your password?
                    </span>
                </TabPanel>
                <TabPanel header="New Account">
                    <FormulateForm @submit="register" #default="{ isLoading, hasErrors }">
                        <div class="input-container">
                            <Label>Name</Label>
                            <FormulateInput
                                type="text"
                                name="name"
                                placeholder="Enter Name" 
                                class="input"
                                validation="required"
                            />
                        </div>
                        <div class="input-container">
                            <Label>Email</Label>
                            <FormulateInput
                                type="email"
                                name="email"
                                placeholder="Enter Email" 
                                class="input"
                                validation="required|email"
                            />
                        </div>
                        <div class="input-container">
                            <Label>Password</Label>
                            <FormulateInput
                                type="password"
                                name="password"
                                class="input"
                                placeholder="Create Password"
                                validation="^required|min:8,length|checkLetters|checkSpecialCharacters"
                                :validation-rules="{
                                    checkLetters: ({ value }) => value.match(/^(?=.*[a-zA-Z])(?=.*\d).+$/g),
                                    checkSpecialCharacters: ({ value }) => value.match(/^.*[!@#$%^&*()_+].*$/g)
                                }"
                                validation-name="Password"
                                :validation-messages="{
                                    checkLetters: 'Password requires at least 1 number and 1 letter.',
                                    checkSpecialCharacters: 'Password requires at least 1 special character (!, @, #, etc.)'
                                }"
                            />
                        </div>
                        <ul class="password-requirements">
                            <li>Minimum 8 characters</li>
                            <li>Minimum 1 special character (!, @, #, etc.)</li>
                            <li>Minimum 1 number and 1 letter</li>
                        </ul>
                        <div class="field-checkbox">
                            <FormulateInput
                                v-model="newsletterCheck"
                                name="newsletter"
                                type="checkbox"
                                class="checkbox"
                                />
                            <label for="newsletter">Newsletter sign-up</label>
                        </div>
                        <div class="field-checkbox">
                            <FormulateInput
                                v-model="alertCheck"
                                name="alert"
                                type="checkbox"
                                class="checkbox"
                                validation="required"
                                :validation-messages="{ required: 'Terms and condition is required.' }"
                                />
                            <label for="alert">By checking this box, I agree to receive email notifications in accordance with the <router-link class="link" to="/terms-of-service" target='_blank'>Terms of Service.</router-link> I understand that I can unsubscribe at any time.</label>
                        </div>
                        <FormulateInput
                            type="submit"
                            class="button"
                            :disabled="hasErrors || isLoading"
                            :label="isLoading ? 'Submitting...' : 'Sign up'"
                        />
                    </FormulateForm>
                </TabPanel>
            </TabView>
        </section>
        <section v-else>
            <Divider />
            <p class="description">
                Enter your registered email address below, and we'll send you a link to create a new password.
            </p>
            <FormulateForm @submit="forgotPassword" #default="{ isLoading, hasErrors }">
                <div class="input-container">
                    <Label>Email:</Label>
                    <FormulateInput
                        type="email"
                        name="email"
                        placeholder="Enter Email" 
                        class="input"
                        style="padding-right: 16px;"
                        validation="required"
                    />
                </div>
                <FormulateInput
                    type="submit"
                    class="button"
                    :disabled="hasErrors || isLoading"
                    :label="isLoading ? 'Submitting...' : 'Submit'"
                />
            </FormulateForm>
            <span class="link-text" @click="reset = false">Remember your password? Click here to Sign In.</span>
        </section>
        <Toast position="bottom-right" />
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Divider from 'primevue/divider';
import InlineMessage from 'primevue/message';
import Toast from 'primevue/toast';
import { apiURL } from '../util.js'


export default {
    name: "Authentication",
    components: {
        Dialog,
        TabView,
        TabPanel,
        Divider,
        InlineMessage,
        Toast
    },
    props:{
        display: Boolean,
    },
    data(){
        return{
            tabIndex: 0,
            reset: false,
            resetEmail: '',
            signin:{},
            apiURL: apiURL,
            validationError: false,
            newsletterCheck: false,
            alertCheck: false,
        }
    },
    computed: {
        header(){
            if(this.tabIndex === 0 && !this.reset) return 'Sign in'
            else if(this.reset) return 'Reset Password'
            else return 'Create an account'
        },
        modalShow:{ 
            get:function () {
                return this.display
            },
            set: function(newValue){
                this.$emit('toggle', newValue);
            }
        }
    },
    methods: {
        async login(data) {
            try {
                this.axios.post(`${apiURL}/api/auth/local`, data).then((resp) => {
                    this.tabIndex = 0;
                    this.$store.dispatch('login', resp.data);
                    this.modalShow = false;
                    this.$router.push('/account');
                }).catch((resp) =>{
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: resp.response?.data?.error?.message, life: 3000 });
                });
            } catch(e) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e.response?.data?.error?.message, life: 3000 });
                this.error = true;
            } 
        },
        async register(data) {
            const tobeSubmitted = data;
            tobeSubmitted['username'] = data.email.substring(0, data.email.indexOf("@"));
            try {
                const resp = await this.axios.post(`${apiURL}/api/auth/local/register`, tobeSubmitted);
                if(resp.status === 200){
                    this.tabIndex = 0;
                    this.$store.dispatch('login', resp.data);
                    this.modalShow = false;
                    this.$router.push('/account');
                }else{
                    this.error = true;
                }
            } catch(e) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e.response?.data?.error?.message, life: 3000 });
                this.error = true;
            } 
        },
        async forgotPassword(data) {
            try {
                const resp = await this.axios.post(`${apiURL}/api/auth/forgot-password`, data);
                // close dialog, go to my profile or my alert
                this.tabIndex = 0;
                if(resp.status === 200){
                    this.tabIndex = 0;
                    this.reset = false;
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: 'An email will be sent to your inbox to reset your password.', life: 3000 });
                }else{
                    this.error = true;
                }
            } catch(e) {
                this.error = true;
            } 
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .p-tabview-nav{
    justify-content: center;
}

::v-deep .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
    border-color: #EF6A40;
    color: #EF6A40;
}


::v-deep .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus{
    box-shadow: unset;
}

::v-deep .p-component.p-password-input{
    width: 100%;
}

.button{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

::v-deep .formulate-input[data-classification=button] button{
    border-color: #EF6A40;
    background: #EF6A40;
    min-width: 120px;
    span{
        text-transform: uppercase;
        margin: 0 auto;
    }
}

.input-container{
    display: flex;
    align-items: center;
    margin-top: 1rem;
    label{
        width: 30%;
        text-align: right;
        margin-right: 1rem;
    }
    .input{
        width: 300px;
        max-width: 100%;
    }

}

.link-text{
    display: block;
    text-align: center;
    color: #EF6A40;
    margin: 30px 50px 0;
    &:hover{
        cursor: pointer;
        text-decoration: underline;
    }
}

.password-requirements{
    margin-left: calc(30% + 16px);
    padding-left: unset;
    list-style-type: none;
    opacity: 0.5;
}

.field-checkbox{
    margin-left: calc(30% + 16px);
    display: flex;
    margin-top: 10px;
}

.checkbox{
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 0;
}

.description{
    text-align: center;
    max-width: 300px;
    margin: 20px auto;
}

.incorrect-password-error{

}

.link{
    color: #EF6A40;
    &:hover{
        cursor: pointer;
        text-decoration: underline;
    }
}

::v-deep .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
    border-bottom: 10px solid;
}

::v-deep .p-tabview .p-tabview-nav li .p-tabview-nav-link{
    border-bottom: 0;
}

@media (max-width: $mobile) {
    .services{
        margin: 79px 0;
    }
    .field-checkbox{
        margin-left: 20px;
    }
}
</style>